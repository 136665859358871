
let GLOBAL_CONFIG_PARAMS = [];

$(document).ready(function()
{
  window.addEventListener('keydown', function(e) {
  	if(e.code === 'Escape') {
    	closeNav();
  	}
  });
  
  getConfigParams();
  
  initTooltips();

	setSeoOptimization();

	HotInfo.init();

	//-- I don't know what the fuck this is...
	$('.ui-helper-hidden-accessible').remove();

});

function closeNav()
{
	$('#navbarCollapse').removeClass('show');
}

function initTooltips()
{
	//-- initialize tooltips
  var du = 3000;
  $("[data-toggle='tooltip']").tooltip({
    //hide: true,
    show: {
      //effect: 'slideDown'
    },
    //track: true,
    open: function(event, ui) {
      setTimeout(function() {
        $(ui.tooltip).hide('explode');
        //$(ui.tooltip).hide('slideUp');
        //$(ui.tooltip).hide('fadeOut');
        //$(ui.tooltip).hide('slide');
        //$(ui.tooltip).hide('blind');
      }, du);
    },
    position: {
      my: "center bottom-5",
      at: "center top-5",
      using: function( position, feedback ) {
        $( this ).css( position );
        $( "<div>" )
          .addClass( "arrow" )
          .addClass( feedback.vertical )
          .addClass( feedback.horizontal )
          .appendTo( this );
      }
    }
  });
}
function setSeoOptimization()
{
	//-- set ALT for <IMG>
	const imgs = $('img');
	imgs.prop('alt', '');

	//-- fixed HrefLang tags with full domain name
	const links = $('[hreflang]');
	const lnk = window.location.origin;
	links.each(function() {
		const obj = $(this);
		const href = obj.prop('href');
		const newHref = lnk+href;
		obj.prop('href', newHref);
	});
}

function gotoLink(code)
{
  const lnk2 = $('.lnk2[data-info=' + code + ']')
  const citiesBlock = $('.cities-block')
  citiesBlock.css('display', 'none')
  if (lnk2.length === 1) {
    $('.city-info').css('display', 'none')
    const cityInfoBlock = $('.city-info[city-name=' + code + ']')
    if (cityInfoBlock.length === 1) {
      citiesBlock.css('display', 'block')
      cityInfoBlock.css('display', 'block')
      Funcs.scrollToAnchor('cities_info_blocks', 'div')
    }
  }
}
function gotoBlogLink(code)
{
  Funcs.scrollToAnchor('blog_country_' + code, 'h4')
  return ''
}
function showBlogRelatedItems()
{
  const hiddenItems = $('.info_cities_list .articles_body .hidden_related_items')
  const moreLink = $('.info_cities_list .articles_body .more_link')

  moreLink.css('display', 'none')
  hiddenItems.css('display', 'block')
}


function getConfigParams()
{
	//const ajaxUrl = window.location.origin + '/ru/confparams';
	const ajaxUrl = '/ru/confparams';
	const dt = new Date();
	const params = {
  	dt: dt.getTime(),
  };
	$.post(
    ajaxUrl,
    params,
    function(data) {
    	//var res = $(xml).find('result').text().replace(/\n/g, '<br/>');
    	GLOBAL_CONFIG_PARAMS = data;
    }
  );
  return false;
}

let fancyboxCurrentPID = -1;
let fancyboxLikesClone = null;
let fancyboxLikesNum = null;
function fancyboxBeforeClose()
{
	if(fancyboxLikesClone !== null) {
		//-- remove "Likes" element from fancybox Toolbar
		fancyboxLikesClone.remove();
		//-- destroy "Likes" element
		fancyboxLikesClone = $();
	}
}
function fancyboxAfterShow()
{
	//-- show "Likes" element in fancybox Toolbar
	if(fancyboxLikesClone !== null) {
		fancyboxLikesClone.show();
	}
}
function fancyboxBeforeLoad()
{
	const fancyboxToolbar = $('.fancybox-toolbar');
	const fancyboxCaption = $('.fancybox-caption');
	if(fancyboxCaption.length === 1) {
		//-- get PID from fancybox Caption
		const arr = fancyboxCaption.html().split('|');
		if(arr.length === 2) {
			fancyboxCaption.html(arr[0]);
			const pid = parseInt(arr[1]);
			//-- save PID
			fancyboxCurrentPID = pid;
			const currLikesHtmlTemplate = $('.likes-html-template[data-pid='+pid+']');
			if(currLikesHtmlTemplate.length === 1) {
				//-- clone "Likes" element and append it to fancybox Toolbar
				fancyboxLikesClone = currLikesHtmlTemplate.clone();
				fancyboxLikesClone.appendTo(fancyboxToolbar);
			}
		}
	}
}

function reloadLikes(lang, article, sid)
{
	const apiBaseUrl = GLOBAL_CONFIG_PARAMS.apiBaseUrl;
	const ajaxUrl = apiBaseUrl + '/web/likes';

	const dt = new Date();
	const params = {
  	dt: dt.getTime(),
  	lang: lang,
  	id: article,
  	sid: sid,
  	just_count:0
  };
	
	$.post(
    ajaxUrl,
    params,
    function(like) {
    	const lnk = window.location.pathname;
    	let obj = null;
			const lnkBlog = lnk.substr(0,9);
			if(lnkBlog === '/ru/blog/' || lnkBlog === '/en/blog/') {
				//-- block "Blog"
				obj = $('.blog_likes .blog_big_like_icon.flag_likes_blog');
			} else {
				//-- block "Gallery" and cloned "Likes" element exists?
				if(fancyboxLikesClone !== null) {
					obj = fancyboxLikesClone.find('.gallery_likes .blog_big_like_icon.flag_likes_blog');
				}
			}
    	if(obj !== null && obj.length === 1) {
    		if(typeof(like) !== 'undefined') {
    		  //-- set "likes" value for current blog/image
  	  		obj.html(like);
  	  		const thereAreLikesMain = $('.there_are_likes_main_'+fancyboxCurrentPID);
  	  		//-- trying to set "likes" value for small image
  	  		if(thereAreLikesMain.length === 1) {
  	  			thereAreLikesMain.html(like);
  	  		}
  	  		const currLikesHtmlTemplate = $('.likes-html-template[data-pid='+fancyboxCurrentPID+']');
	  			//-- trying to set "likes" value for template
	  			if(currLikesHtmlTemplate.length > 1) {
  					currLikesHtmlTemplate.attr('likes', like);
  					const template = currLikesHtmlTemplate.find('.gallery_likes .blog_big_like_icon.flag_likes_blog');
  					if(template.length > 1) {
  						template.html(like);
						}
	  			}
    		}
    	}
    }
  );
  return false;
}
