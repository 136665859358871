
let map = null;
let api = null;
let latCenter = 59.934280
let lngCenter = 30.335099
let center = { lat: parseFloat(latCenter), lng: parseFloat(lngCenter) };
let bounds = {}

let savedCenter = {}
let savedZoom = 10
let savedBounds = {}

let geoXml = []
let markers = []
let polygons = []
let polylines = []

let gMapZoom = 10;
let gMapData = [];
let gMapKmlData = [];
let infoWins = []
let infowindow = null

let locations = {}
let kmls = {}

let language = 'ru';

document.addEventListener('DOMContentLoaded', function()
{
	//-- using for the "Country" page only!
	const pathPage = Urls.getUrlParams().pathPage;
	const pathLang = Urls.getUrlParams().pathLang;
	if(pathPage !== 'country') {
		return false;
	}
	if(typeof(pathLang) !== 'undefined') {
		language = pathLang;
	}

	//-- use GoogleMaps API
	api = google.maps;

	const osmMapTypeName = 'OSM';
  const styledMapTypeName = (language === 'ru') ? 'Стили' : 'Styled';
	const mapOptions = {
    zoom: 14,
    center: center,
    panControl: true,
    scaleControl: true,
    mapTypeControlOptions: {
      mapTypeIds: [
        api.MapTypeId.ROADMAP,
        api.MapTypeId.SATELLITE,
        api.MapTypeId.HYBRID,
        api.MapTypeId.TERRAIN,
        osmMapTypeName,
        styledMapTypeName,
      ],
      style: api.MapTypeControlStyle.DROPDOWN_MENU
    },
    zoomControl: true,
    zoomControlOptions: {
      style: api.ZoomControlStyle.SMALL
    }
  }


	var container = document.getElementById('gmap0-map-canvas');
	container.style.width = '100%';
	container.style.height = '400px';
	
	map = new google.maps.Map(container, mapOptions);

	//-- set map types for OSM and Styled maps
  map.mapTypes.set(osmMapTypeName, createOSMLayer(osmMapTypeName, language))
  map.mapTypes.set(styledMapTypeName, createStyledLayer(styledMapTypeName));
  
  //-- choose which map will be loaded first
  map.setMapTypeId(api.MapTypeId.ROADMAP)
  //map.setMapTypeId(api.MapTypeId.SATELLITE)
  //map.setMapTypeId(api.MapTypeId.HYBRID)
  //map.setMapTypeId(api.MapTypeId.TERRAIN)
  //map.setMapTypeId(osmMapTypeName)
  //map.setMapTypeId(styledMapTypeName);

	//-- ??? listen click-event on Map ???
  api.event.addListener(map, 'click', () => {
  	//-- close all info windows
  	closeAllInfoWindows();
  })

  gMapData = $.parseJSON(gMapDataJson);

  let markersList = {}

  let idx = 0;

  if(typeof(gMapData) === 'undefined') {
  	return false;
  }

  gMapData.forEach((el) => {
  	
  	const lat = parseFloat(el.latLng[0])
  	const lng = parseFloat(el.latLng[1])

  	markersList[idx] = {
  		id: idx,
  		title: el.name,
  		text: el.text,
  		position: {
  			lat: parseFloat(lat),
  			lng: parseFloat(lng)
  		},
			active: true,
			visible: true,
			//anchorPoint: 'CENTER',
  		zIndex: 5,
  		icon: {
  			anchor: {
  				x: 16,
  				y: 32
  			},
  			url: '/images/city_32x32_01.png',
  			//url: 'http://maps.google.com/mapfiles/kml/shapes/subway.png',
      	scaledSize: {height: 32, width: 32},
      	size: {height: 32, width: 32},
      	optimised: true
  		},
  		map: map
  	}
  	idx++
  })
  locations = markersList
  //console.log(locations);

  //-- list of makers from main locations
  for(const [i,location] of Object.entries(locations)) {
		const marker = new api.Marker(locations[i]);
  	api.event.addListener(marker, 'click', function(evt) {
	    //-- close all info windows
  		closeAllInfoWindows();
	    
	    infowindow = new api.InfoWindow(infoWinOptions(marker));
	    infowindow.open(map, marker);
	  })
  }

	//-- "true" means: save map settings on first use
	allocateGpsData(true)
});
const zoomEvent = (jsonMarker) =>
{
	const marker = $.parseJSON(jsonMarker);
  const lat = parseFloat(marker.position.lat)
  const lng = parseFloat(marker.position.lng)
  center = { lat:lat, lng:lng };

  //-- add extra 4 places around the center of the marker
  const delta = 0.01;
  //-- at bottom-left
  const dimBL = { lat:(lat - delta), lng:(lng - delta) };
  //-- at top-right
  const dimTR = { lat:(lat + delta), lng:(lng + delta) };
  //-- at top-left
  const dimTL = { lat:(lat + delta), lng:(lng - delta) };
  //-- at bottom-right
  const dimBR = { lat:(lat - delta), lng:(lng + delta) };
  let places = [center,dimBL,dimTR,dimTL,dimBR];

  const bounds = new google.maps.LatLngBounds();
  for(var i = 0, place; place = places[i]; i++) {
    bounds.extend(place);
  }
  map.fitBounds(bounds);
}
const toggleLayer = (block_id) =>
{
  //-- remove all KML=items from markers list
  const objKmlsMarkersList = $('.kmls-markers-list');
  if(objKmlsMarkersList.length > 0) {
		objKmlsMarkersList.remove();
  }
  
  //-- change icon style on click
  const obj = $('#'+block_id+' .access');
  if(obj.length === 0) {
    return false
  }
  const parentObj = obj.parent()
  if(typeof(parentObj) === 'undefined') {
    return false
  }
  const active = parentObj.hasClass('active')
  if(active) {
    parentObj.removeClass('active')
  } else {
    parentObj.addClass('active')
  }

  //-- make a list of KML-files to be parsed
  const kml = []
  let idx = 0
  const dsGpsData = $('#info_downloads_list_block .gps_data_download.active .gps_data')
  dsGpsData.each(function () {
    const data = $(this).text()
    kml[idx] = data
    idx++
  })

  //-- list of all KML-files to be parsed
  gMapKmlData = kml;

  kmlChanged();
}
const showAll = () => {
  
  // -- is there are no KML files, just show points
  if(gMapKmlData.length === 0) {
    
    center = savedCenter
    bounds = savedBounds
    gMapZoom = savedZoom
    
    map.panTo(center)
    map.fitBounds(bounds)
    return false
  }

  if(!geoXml || !geoXml.docs) {
    return false
  }
  for(let j = 0; j < geoXml.docs.length; j++) {
    bounds.union(geoXml.docs[j].bounds)
    geoXml.showDocument(geoXml.docs[j])
  }
  map.fitBounds(bounds)
}
const closeAllInfoWindows = () => {
  //console.error(infowindow);
  if(infowindow !== null) {
    api.event.clearInstanceListeners(infowindow);
    infowindow.close();
    infowindow = null;
  }
}
const getAllGeoXml3Docs = () => {
  let i, j, k, doc = []

  markers = []
  polygons = []
  polylines = []

  let markersList = {}
  
  kmls = markersList
  
  if(!geoXml || !geoXml.docs) {
    return false
  }

  let idx = 1000;

  for(j = 0; j < geoXml.docs.length; j++) {
    doc[j] = []
    
    if(typeof(geoXml.docs[j].markers) !== 'undefined') {
      markers = geoXml.docs[j].markers
      for(i = 0; i < markers.length; i++) {
      	let el = markers[i];
      	
      	geoXml.docs[j].markers[i].icon = {
      		anchor: {
      			x: 16,
      			y: 32
      		},
      		//url: '/images/city_32x32_01.png',
      		//url: 'http://maps.google.com/mapfiles/kml/shapes/subway.png',
          scaledSize: {height: 32, width: 32},
          size: {height: 32, width: 32}
        };
      	
      	markersList[idx] = {
      		id: idx,
      		title: el.title,
      		text: '',
      		position: {
      			lat: el.position.lat(),
      			lng: el.position.lng(),
      		},
					active: el.active,
					visible: el.visible,
      		zIndex: el.zIndex,
      		icon: el.icon
      	}
      	idx++
      }
      doc[j].push(markers)
    }
    if(typeof(geoXml.docs[j].polygons) !== 'undefined') {
      polygons = geoXml.docs[j].polygons
      //doc[j].polygons = polygons
      doc[j].push(polygons)
    }
    if(typeof(geoXml.docs[j].polylines) !== 'undefined') {
      polylines = geoXml.docs[j].polylines
      doc[j].push(polylines)
    }
  }

  kmls = markersList
  
  //-- build a list of KML markers
  const objMarkerList = $('.marker-list');
  const objKmlsMarkersContainer = $('.kmls-markers-container');
  if(objKmlsMarkersContainer.length === 1) {
  	const objKmlsMarkersList = $(objKmlsMarkersContainer.html());
  	objKmlsMarkersList.removeClass('kmls-markers-list-hidden');
  	objKmlsMarkersList.addClass('kmls-markers-list');
  	objKmlsMarkersList.attr('style', null)

  	for(const [i,kml] of Object.entries(kmls)) {
			//if(i == 1000) {
			//	console.log(i, kml, jsonKml);
			//}
			const newKmlsMarkersList = objKmlsMarkersList.clone();
			const markerListClick = newKmlsMarkersList.find('.marker-list-click');
			if(markerListClick.length === 1) {
				//-- "click" class found => make event handling onClick
				const jsonKml = JSON.stringify(kml);
				markerListClick.click(function() {
					zoomEvent(jsonKml);
				});
				const markerListItem = newKmlsMarkersList.find('.marker-list-item');
				if(markerListItem.length === 1) {
					//-- replace ID and TITLE with POI values
					const itemId = markerListItem.prop('id').replace('[MARKER-ID]', kml.id);
					const itemTitle = markerListItem.prop('title').replace('[MARKER-TITLE]', kml.title);
					markerListItem.prop('id', itemId)
					markerListItem.prop('title', itemTitle)
					const markerListItemTitle = markerListItem.find('.marker-list-item-title');
					if(markerListItemTitle.length === 1) {
						markerListItemTitle.html(itemTitle);
						//-- append POI into the list of KMLs
						objMarkerList.append(newKmlsMarkersList);
					}
				}
			}
  	}
  	initTooltips();
  }
  
  infoWins = []
  //-- handler of all KML-items
  for(i = 0; i < doc.length; i++) {
  	const docs = doc[i][0]
  	//console.log(docs.length);
    for(k = 0; k < docs.length; k++) {
      //-- listen click-event on geoXml3 markers
      const marker = docs[k];
      api.event.addListener(marker, 'click', function(evt) {
	      //-- close all info windows
  			closeAllInfoWindows();
	      
	      //marker.infoWindowOptions = infoWinOptions(marker)
	      infowindow = marker.infoWindow;
	      
	      infowindow.open(map, marker);
	    })
    }
  }

}
const runGeoXML3Parser = () => {
  
  //-- parse KML/KMZ files => load markers
  geoXml = new window.geoXML3.parser({
    map: map,
    zoom: true,
    markerOptions: {
      optimized: false
    },
    zIndex: 5,
    visible: true,
    singleInfoWindow: true,
  })
  geoXml.parse(gMapKmlData)

  //-- listen parsed-event for geoXml3 data
  api.event.addListener(geoXml, 'parsed', () => {
    setTimeout(() => {
  		getAllGeoXml3Docs()
		}, 250)
  })
}
const allocateGpsData = (itStarts) => {
  
  //-- there are no data at all => exit
  if((!$.isArray(gMapData) || gMapData.length == 0)) {
    return false
  }

  // -- Using array of points
  latCenter = 0
  lngCenter = 0

  bounds = new api.LatLngBounds();
  
  let lat = 0
  let lng = 0

  const gMapDataLength = gMapData.length
  for (let j = 0; j < gMapDataLength; j++) {
    lat = parseFloat(gMapData[j].latLng[0])
    lng = parseFloat(gMapData[j].latLng[1])

    const oneLatLng = new api.LatLng(lat, lng)
    bounds.extend(oneLatLng);
  
    latCenter += lat
    lngCenter += lng
  }

  latCenter /= gMapDataLength
  lngCenter /= gMapDataLength

  lat = parseFloat(latCenter)
  lng = parseFloat(lngCenter)
  center = { lat:lat, lng:lng };

  //-- set new center & bounds
  //-- save current map
  if(itStarts) {
  	savedCenter = center
  	savedBounds = bounds
  	savedZoom = gMapZoom
  }
  
  map.panTo(center)
  //map.setCenter(bounds.getCenter());
  map.fitBounds(bounds)
  
  //-- there are no KML/KMZ data => exit
  if(!$.isArray(gMapKmlData) || gMapKmlData.length == 0) {
    return false
  }
  
  if(typeof(window.geoXML3) !== 'undefined') {
    runGeoXML3Parser()
  }

}
const hideAllKml = () => {
  if(!geoXml || !geoXml.docs) {
    return false
  }

  let i, j

  for(i = 0; i < markers.length; i++) {
  	markers[i].setMap(null);
  }

  for(j = 0; j < geoXml.docs.length; j++) {
    for(i = 0; i < geoXml.docs[j].placemarks.length; i++) {
      var placemark = geoXml.docs[j].placemarks[i];
      if(placemark.polygon) {
        placemark.polygon.setMap(null);
      }
      if(placemark.polyline) {
        placemark.polyline.setMap(null);
      }
      if(placemark.marker) {
        placemark.marker.setMap(null);
      }
    }
  }
}
const showAllKml = () => {
  if(!geoXml || !geoXml.docs) {
    return false
  }
  
  let i, j
  
  for(j = 0; j < geoXml.docs.length; j++) {
    for(i = 0; i < geoXml.docs[j].placemarks.length; i++) {
      var placemark = geoXml.docs[j].placemarks[i];
      if(placemark.polygon) {
        placemark.polygon.setMap(map);
      }
      if(placemark.polyline) {
        placemark.polyline.setMap(map);
      }
      if(placemark.marker) {
        placemark.marker.setMap(map);
      }
    }
  }
}
const makeDownloadsList = () => {
  //-- make downloads list
  const infoDownloadsListBlock = $('#info_downloads_list_block')
  const gpsDataDownload = $('#info_downloads_list_block .gps_data_download')

  // -- add number of files for download
  let obj = $('div[name="title_item_downloads"]')
  if (obj.length > 0) {
    const data_title = obj.attr('data-title')
    obj.html(data_title + ' (' + gpsDataDownload.length + ')')
  }
  gpsDataDownload.each(function () {
    $('#info_downloads_list_block_empty_info').hide()
    $(this).appendTo(infoDownloadsListBlock).show()
  })

  const parent = infoDownloadsListBlock.parent()

  const id = parent.attr('id')
  const name = parent.attr('name')
  if (id !== null && typeof (id) !== 'undefined' && name !== null && typeof (name) !== 'undefined') {
    const infoBlockTitleItemObj = $('div[name="' + name + '"]')
    infoBlockTitleItemObj.show()
  }
}

//-- watch the changes of KML-data
const kmlChanged = () => {
	hideAllKml()
  allocateGpsData(false)
};

const infoWinOptions = (marker) => {
	let content = '<b>' + marker.title + '</b>'
  if(marker.text !== '' && typeof(marker.text) !== 'undefined' && marker.text !== null) {
    content += '<br/>' + marker.text
  }

  //-- add "To Here" & "From Here" links
  const latlng = marker.position.toUrlValue();
  var url = 'http://maps.google.com/maps?sll=' + latlng;
  var address = encodeURIComponent(latlng).replace(/\%20/g, '+');
  const geDirections = '<a href="' + url + '&daddr=' + address + '" target=_blank>To Here</a> - <a href="' + url + '&saddr=' + address + '" target=_blank>From Here</a>';
  content += '<br/>' + geDirections

  return {
  	content: content,
  	maxWidth: 400
  }
}
const createOSMLayer = (osmMapTypeName, lang) => {
  
  //-- get API key from https://thunderforest.com/
  const tileServer = 'http://a.tile.thunderforest.com/cycle'
  const osmMapTypeOptions = {
    getTileUrl: function (coord, zoom) {
      return tileServer + '/' + zoom + '/' + coord.x + '/' + coord.y + '.png?apikey=c78f8bd2166d4303bd0da87f28824a34&lang=' + lang
    },
    tileSize: new api.Size(256, 256),
    isPng: true,
    maxZoom: 19,
    minZoom: 0,
    name: osmMapTypeName
  }
  return new api.ImageMapType(osmMapTypeOptions)
}
const createStyledLayer = (styledMapTypeName) => {
  
  return new api.StyledMapType(
    [
      {
        elementType: "geometry",
        stylers: [{ color: "#ebe3cd" }]
      },
      {
        elementType: "labels.text.fill",
        stylers: [{ color: "#523735" }]
      },
      {
        elementType: "labels.text.stroke",
        stylers: [{ color: "#f5f1e6" }]
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9b2a6" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [{ color: "#dcd2be" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ae9e90" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#93817c" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#a5b076" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#447530" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#f5f1e6" }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#fdfcf8" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#f8c967" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#e9bc62" }],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [{ color: "#e98d58" }],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [{ color: "#db8555" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#806b63" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8f7d77" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ebe3cd" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#b9d3c2" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#92998d" }],
      },
    ],
    {
      name: styledMapTypeName,
    }
  );
}