
const HotInfo = {
  init () {
    let itemObj = $('#news_container_index .issue_content_news_item, #news_container_gallery .issue_content_news_item')
    itemObj.unbind('mouseenter')
    itemObj.unbind('mouseleave')
    itemObj.bind('mouseenter', function () {
      const container = $(this)
      const imgHotInfo = container.find('.main_hotinfo_div1')
      if (typeof (imgHotInfo) !== 'undefined') {
        imgHotInfo.css('display', 'block')
      }
    }).bind('mouseleave', function () {
      const container = $(this)
      const imgHotInfo = container.find('.main_hotinfo_div1')
      if (typeof (imgHotInfo) !== 'undefined') {
        imgHotInfo.css('display', 'none')
      }
    })

    itemObj = $('#news_container .issue_content_news_item')
    // if(TEMPLATE === 'blog') {
    //  itemObj = $('#news_container_blog .issue_content_news_item');
    // }
    itemObj.each(function () {
      const containerWidth = $(this).width()
      const imgBig = $(this).find('.img_big')
      const imgHotInfo = $(this).find('.img_hotinfo_div .text')
      const imgBigWidth = imgBig.width()
      const imgMarginLeft = parseInt((imgBigWidth - containerWidth) / 2)
      if (imgMarginLeft > 0) {
        imgBig.css('margin-left', '-' + imgMarginLeft + 'px')
        if (typeof (imgHotInfo) !== 'undefined') {
          imgHotInfo.css('margin-left', '-' + (imgMarginLeft / 2) + 'px')
        }
      }
    })
    // var obj = $('#news_container .issue_content_news_item .inner .flag, #news_container .issue_content_news_item .inner .issue_content_news_item_title, #news_container .issue_content_news_item .inner .issue_content_news_item_subtitle');
    // var obj = $('#news_container .issue_content_news_item');
    const obj = itemObj
    obj.unbind('mousedown')
    obj.unbind('mouseover')
    obj.unbind('mouseout')
    obj.bind('mousedown', function () {
      /*
      if(TEMPLATE === 'gallery' && PAGE_SID === '' && PAGE_ID === '') {
        var targetName = event.target.name;
        if(targetName !== 'gallery_item_href') {
          var container = $(this).parents('.issue_content_news_item');
          var container = $(this);

          var details = container.find('.issue_content_news_item_list');
          var isNone = (details.css('display') == 'none') ? true : false;
          details.css('display', isNone ? 'block' : 'none');
          $container.masonry('reloadItems').masonry();
          //setMainPageBlockHeight();
        }
      }
      */
    }).bind('mouseenter', function () {
      // var container = $(this).parents('.issue_content_news_item');
      const container = $(this)

      const imgBig = container.find('.img_big')
      const imgHotInfo = container.find('.img_hotinfo_div')
      const imgSmall = container.find('.img_small')
      const subTitle = container.find('.issue_content_news_item_subtitle')
      const topTitle = container.find('.issue_content_news_item_title')
      imgBig.css('display', 'block')
      if (typeof (imgHotInfo) !== 'undefined') {
        imgHotInfo.css('display', 'block')
      }
      imgSmall.css('display', 'none')
      subTitle.css('display', 'block')
      // if(TEMPLATE === 'index' || TEMPLATE === 'gallery') {
      topTitle.css('display', 'none')
      // }
    }).bind('mouseleave', function () {
      // var container = $(this).parents('.issue_content_news_item');
      const container = $(this)

      const imgBig = container.find('.img_big')
      const imgHotInfo = container.find('.img_hotinfo_div')
      const imgSmall = container.find('.img_small')
      const subTitle = container.find('.issue_content_news_item_subtitle')
      const topTitle = container.find('.issue_content_news_item_title')

      // var subTitle = $('.issue_content_news_item_subtitle');
      // var topTitle = $('.issue_content_news_item_title');

      imgBig.css('display', 'none')
      if (typeof (imgHotInfo) !== 'undefined') {
        imgHotInfo.css('display', 'none')
      }
      imgSmall.css('display', 'block')
      subTitle.css('display', 'none')
      // if(TEMPLATE === 'index' || TEMPLATE === 'gallery') {
      topTitle.css('display', 'block')
      // }
    })
  }
}
