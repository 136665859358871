
const Urls = {
  getUrlParams() {
    // -- get URL: /ru/gallery/uk-en/london/15219
    // -- pathLang:ru, pathPage:gallery, pathCode:uk-en, pathCode2:london, pathId:15219
    let path = window.location.pathname
    const localeCodes = ['ru','en'];
    //-- check if there is LANG in route
    const pathLength = path.length
    const isLang = (localeCodes.indexOf(path.substr(0,2)) !== -1)
	  const isSlash = (path.substr(2,1) === '/')
  	if(pathLength > 2 && (!isLang || !isSlash)) {
    	path = path.substr(1);
    }

    let [pathLang, pathPage, pathCode, pathCode2, pathId] = path.split('/')
    return { pathLang, pathPage, pathCode, pathCode2, pathId }
 	},
}
