
const MapVector = {
	
	setJVM(data) {

    const DEBUG = false;
    const LANGUAGE = data.LANGUAGE
  
    if (typeof (data.vectorMapData) === 'undefined') {
      return false
    }
  
    const visitedCountries = data.visitedCountries
    const planningCountries = data.planningCountries
    const myHomeCountry = data.myHomeCountry
    const vectorMapData = data.vectorMapData
    // var vectorMapDistances = data.vectorMapDistances;
    const vectorMapMyHomeCountry = data.vectorMapMyHomeCountry
    const vectorMapVisitedCountries = data.vectorMapVisitedCountries
    const vectorMapPlanningCountries = data.vectorMapPlanningCountries
    // var vectorMapSelectedRegions = data.vectorMapSelectedRegions;
    // let earth = data.earth;
    // let moon = data.moon;
    
    let map
    // var markers = [];
    let series_regions_scale = null
    let series_regions_scale_plans = null
    const countryTitle = (LANGUAGE == 'ru') ? 'Страны' : 'Countries'
  
    function getVectorMapData () {
      return vectorMapData
    }
    /*
    function getVectorMapDistances() {
      return vectorMapDistances;
    }
    */
  
    for (const k in vectorMapMyHomeCountry) {
      const v = vectorMapMyHomeCountry[k]
      if (v === 'myHomeCountry') {
        vectorMapMyHomeCountry[k] = myHomeCountry
      }
    }
    for (const k in vectorMapVisitedCountries) {
      const v = vectorMapVisitedCountries[k]
      if (v === 'visitedCountries') {
        vectorMapVisitedCountries[k] = visitedCountries
      }
      if (v === 'myHomeCountry') {
        vectorMapVisitedCountries[k] = myHomeCountry
      }
    }
    // vectorMapVisitedCountries['RU'] = myHomeCountry;
    for (const k in vectorMapPlanningCountries) {
      const v = vectorMapPlanningCountries[k]
      if (v === 'planningCountries') {
        vectorMapPlanningCountries[k] = planningCountries
      }
    }
  
    initVectorMap()
  
  
    function closeSecondLevelMap () {
      const mainMapObj = $('#vector_map')
      const vectorMapPlaceCloseBtn = $('#vector_map_place .close_btn')
  
      vectorMapPlaceCloseBtn.click(function () {
        const daddy = $(this).parent()
        // daddy.css('display', 'none');
        daddy.remove()
        $(mainMapObj).css('display', 'block')
        initVectorMap()
      })
    }
    function navigateTo(url, key) {
      //console.log(url, key);
      //return false;
    	window.location = url;
    }
    function initVectorMap (mapArea) {
      // var mainObjectStr = '#vector_map_place #vector_map';
      const topObjectStr = '#vector_map_place'
      const mainObjectStr = '#vector_map'
      let currentObjectStr = mainObjectStr
  
      if (mapArea === null || typeof (mapArea) === 'undefined' || mapArea === '') {
        mapArea = 'world_mill'
      } else {
        currentObjectStr = topObjectStr + ' .' + mapArea
        let obj = $(currentObjectStr)
        if (obj.length === 0) {
          const divStr = '<div class="' + mapArea + '"><div class="close_btn"></div></div>'
          const divObj = $(divStr)
          $(topObjectStr).append(divObj)
          obj = $(currentObjectStr)
        }
        closeSecondLevelMap()
      }
  
      let markers = [{
        latLng: [59.95, 30.316667],
        name: 'Saint-Petersburg',
        code: 'saint_petersburg',
        country: 'ru',
        country_title: 'Russia',
        region: 'leningrad_region'
      }]
      let mapAllMarkers = null
      try {
        mapAllMarkers = getVectorMapData()
      } catch (e) {
        this.error.push(e)
      }
      if (typeof (mapAllMarkers) !== 'undefined' && mapAllMarkers !== null) {
        if (mapAllMarkers.length > 0) {
          markers = mapAllMarkers
        }
      }
      const params = [
        'backgroundColor',
        'markerStyle_initial_fill',
        'markerStyle_selected_fill',
        'regionStyle_initial_fill',
        'regionStyle_selected_fill',
        'regionStyle_selectedHover_fill',
        'series_regions_scale1',
        'series_regions_scale2',
        'series_regions_scale_myhome',
        'series_regions_scale_myhomeHover',
        'series_regions_scale_visited',
        'series_regions_scale_plans_planning',
        'series_regions_scale_other'
      ]
      const arrColors = []
      for (let i = 0; i < params.length; i++) {
        const vMapCls = params[i]
  
        $('<div class="' + vMapCls + ' settings"/>').appendTo('#vector_map_place')
        const color = $('#vector_map_place .' + vMapCls).css('color')
        arrColors[vMapCls] = color
      }
  
      if (LANGUAGE == 'ru') {
        series_regions_scale = {
          'не был': arrColors.series_regions_scale_other,
          посетил: arrColors.series_regions_scale_visited,
          родина: arrColors.series_regions_scale_myhome/*,
          'планы': arrColors['series_regions_scale_plans_planning']
          */
        }
        series_regions_scale_plans = {
          планы: arrColors.series_regions_scale_plans_planning
        }
      } else {
        series_regions_scale = {
          other: arrColors.series_regions_scale_other,
          visited: arrColors.series_regions_scale_visited,
          home: arrColors.series_regions_scale_myhome/*,
          'plans': arrColors['series_regions_scale_plans_planning']
          */
        }
        series_regions_scale_plans = {
          plans: arrColors.series_regions_scale_plans_planning
        }
      }
  
      const colors = {}
      colors.ru = 'pink'
  
      // -- clear map to set a new one!
      $('#vector_map').empty()
      map = null
  
      // map = new jvm.MultiMap({
      map = new jvm.Map({
        container: $(currentObjectStr),
        maxLevel: 1,
        map: mapArea,
        backgroundColor: 'transparent',
        // hoverColor: false,
        hoverOpacity: 0.5,
        zoomButtons: 1,
        zoomAnimate: 0,
        zoomStep: 2,
        zoomOnScroll: 1,
        zoomMax: 20,
        zoomMin: 1,
        colors: colors,
        markers: markers,
        markerStyle: {
          initial: {
            fill: arrColors.markerStyle_initial_fill
          },
          selected: {
            fill: arrColors.markerStyle_selected_fill
          }
        },
        regionStyle: {
          initial: {
            fill: arrColors.regionStyle_initial_fill
          },
          hover: {
            // fill: arrColors['series_regions_scale_myhomeHover'],
            cursor: 'pointer'
            // cursor: 'default'
          },
          selected: {
            // fill: arrColors['regionStyle_selected_fill'],
            fill: null
            // cursor: 'pointer'
          },
          selectedHover: {
            // fill: arrColors['regionStyle_selectedHover_fill'],
            fill: null
            // cursor: 'pointer'
          }
        },
        onMarkerTipShow: function (e, el, code) {
          if (DEBUG) {
            console.log(e, el, code)
          }
  
          const marker = markers[code]
          let cityName = marker.name
          //-- use region for Russia
          if(marker.country === 'ru' && marker.region_title !== null) {
            cityName += ', ' + marker.region_title
          }
          cityName += ', ' + marker.country_title
          map.tip.text(cityName)
          const bgcolor = (marker.country === 'ru') ? '#f00' : '#009'
          map.tip.css({ 'background-color': bgcolor })
        },
        onMarkerClick: function (event, index) {
          
          let url = '/gallery/' + markers[index].country
          //-- use region for Russia
          //console.log(markers[index]);
          if(markers[index].country === 'ru' && markers[index].region !== null) {
            url += '/' + markers[index].region
          }
          url += '/' + markers[index].code
          url = '/' + LANGUAGE + url
          //window.open(url, '_blank')
          hideTips()
          navigateTo(url, 1)
        },
        /*
        onRegionOut: function(e, code) {
          document.body.style.cursor = 'default';
        },
        */
        onRegionOver: function (e, code) {
          if (DEBUG) {
            console.log(e, code)
          }
          hideTips()
  
          /*
          var cursor = 'default';
          if(
            vectorMapVisitedCountries[code] === 'visited' ||
            vectorMapVisitedCountries[code] === 'посетил' ||
            vectorMapVisitedCountries[code] === 'home' ||
            vectorMapVisitedCountries[code] === 'родина'
          ) {
            cursor = 'pointer';
          }
          document.body.style.cursor = cursor;
          //$(document).css('cursor', cursor);
          //document.getElementsByTagName("body")[0].style.cursor = cursor;
          //document.getElementsByTagName("div")[0].style.cursor = cursor;
          //$('.jvectormap-container').css('cursor', cursor);
          */
  
          /*
          var pathObj = map.mapData.paths[code];
          var pathName = pathObj.name;
          */
          // var aaa = $(this).css('background-color');
        },
        onRegionTipShow: function (e, el, code) {
          if (DEBUG) {
            console.log(e, el, code)
          }
          const bgcolor = (code === 'RU') ? '#900' : '#333'
          map.tip.css({ 'background-color': bgcolor })
  
          const name = CountryCodes.getCountryLang(code, LANGUAGE)
          map.tip.text(name)
          // var mapInstance = map.getMapObject();
          // var mapData = mapInstance.mapData;
          // var paths = mapData.paths;
  
          // $.each(paths, function(key, val) {});
          // var regionName = mapInstance.getRegionName(code);
        },
        onRegionClick: function (event, code) {
          const visitedObj = vectorMapVisitedCountries[code]
          const planningObj = vectorMapPlanningCountries[code]
          const myHomeObj = vectorMapMyHomeCountry[code]
  
          const cond1 = (visitedObj != null && typeof (visitedObj) !== 'undefined')
          const cond2 = (myHomeObj != null && typeof (myHomeObj) !== 'undefined')
          const cond3 = (planningObj != null && typeof (planningObj) !== 'undefined')
          if (cond1 || cond2 || cond3) {
            code = code.toLowerCase()
            let url = ''
            
            if (code === 'ru') {
              initVectorMap('ru_mill')
              //initVectorMap('ru_fd_mill')
            } else if (code === 'gb') {
              initVectorMap('uk_countries_mill')
            } else {
              if (code === 'eng') {
                code = 'uk-en'
              }
              if (code === 'wls') {
                code = 'uk-ws'
              }
              if (code === 'nir') {
                code = 'uk-ir'
              }
              if (code === 'sct') {
                code = 'uk-sc'
              }
              if(code.substr(0,3) === 'ru-') {
              	//-- Russia + region
              	code = 'ru/' + code
              }
  
              url = '/gallery/' + code
              url = '/' + LANGUAGE + url
              //window.open(url, '_blank')
              hideTips()
              navigateTo(url, 2)
            }
          }
        },
        onRegionSelected: function (event, code, isSelected, selectedRegions) {
          if (DEBUG) {
            console.log(event, code, isSelected, selectedRegions)
          }
          this.hideTips()
        },
        series: {
          regions: [
            {
              scale: series_regions_scale,
              attribute: 'fill',
              values: vectorMapVisitedCountries,
              legend: {
                vertical: true,
                // cssClass: '#45fd78',
                title: countryTitle
              }
            },
            {
              scale: series_regions_scale_plans,
              attribute: 'fill',
              values: vectorMapPlanningCountries,
              legend: {
                vertical: true,
                title: null
              }
            }
          ]
        },
        hideTips () {
          // -- hide all tooltips after loading
          if (typeof (map.tip) !== 'undefined') {
            map.tip.hide()
          }
        }
      })
  
      hideTips()
  
      function hideTips () {
        // -- hide all tooltips after loading
        if (typeof (map.tip) !== 'undefined') {
          map.tip.hide()
        }
        $('.jvectormap-tip').hide()
      }
    } // -- :initVectorMap
  }

}
