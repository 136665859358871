const CountryCodes = {
  // -- get country by code (ru & en)
  getCountry (code) {
    const codesList = this.getCountryCodes()
    if (typeof (code) === 'undefined') {
      code = ''
    }
    code = code.toUpperCase()
    if (typeof (codesList[code]) === 'undefined') {
      return ''
    }
    return codesList[code]
 },
  // -- get country by code and language
  getCountryLang (code, lang) {
    const codesList = this.getCountryCodes()
    if (typeof (code) === 'undefined') {
      code = ''
    }
    if (typeof (lang) === 'undefined') {
      lang = 'ru'
    }
    code = code.toUpperCase()
    if (typeof (codesList[code]) === 'undefined') {
      return ''
    }
    lang = lang.toLowerCase()
    if (lang !== 'ru' && lang !== 'en') {
      lang = 'ru'
    }
    return codesList[code][lang]
 },
  getCountryCodes () {
    return {
      'BD': { ru: 'Бангладеш', en: 'Bangladesh' },
      'BE': { ru: 'Бельгия', en: 'Belgium' },
      'BF': { ru: 'Буркина Фасо', en: 'Burkina Faso' },
      'BG': { ru: 'Болгария', en: 'Bulgaria' },
      'BA': { ru: 'Босния-Герцеговина', en: 'Bosnia and Herz.' },
      'BN': { ru: 'Бруней', en: 'Brunei' },
      'BO': { ru: 'Боливия', en: 'Bolivia' },
      'JP': { ru: 'Япония', en: 'Japan' },
      'BI': { ru: 'Бурунди', en: 'Burundi' },
      'BJ': { ru: 'Бенин', en: 'Benin' },
      'BT': { ru: 'Бутан', en: 'Bhutan' },
      'JM': { ru: 'Ямайка', en: 'Jamaica' },
      'BW': { ru: 'Ботсвана', en: 'Botswana' },
      'BR': { ru: 'Бразилия', en: 'Brazil' },
      'BS': { ru: 'Багамы', en: 'Bahamas' },
      'BY': { ru: 'Беларусь', en: 'Belarus' },
      'BZ': { ru: 'Белиз', en: 'Belize' },
      'RU': { ru: 'Россия', en: 'Russia' },
      'RW': { ru: 'Руанда', en: 'Rwanda' },
      'RS': { ru: 'Сербия', en: 'Serbia' },
      'TL': { ru: 'Тимор', en: 'Timor-Leste' },
      'TM': { ru: 'Туркменистан', en: 'Turkmenistan' },
      'TJ': { ru: 'Таджикистан', en: 'Tajikistan' },
      'RO': { ru: 'Румыния', en: 'Romania' },
      'GW': { ru: 'Гвинея-Бисау', en: 'Guinea-Bissau' },
      'GT': { ru: 'Гватемала', en: 'Guatemala' },
      'GR': { ru: 'Греция', en: 'Greece' },
      'GQ': { ru: 'Экв.Гвинея', en: 'Eq. Guinea' },
      'GY': { ru: 'Гайяна', en: 'Guyana' },
      'GE': { ru: 'Грузия', en: 'Georgia' },
      'GB': { ru: 'Великобритания', en: 'United Kingdom' },
      //= => details for the UK countries
      'UK-EN': { ru: 'Англия', en: 'England' },
      'ENG': { ru: 'Англия', en: 'England' },
      'UK-WS': { ru: 'Уэльс', en: 'Wales' },
      'WLS': { ru: 'Уэльс', en: 'Wales' },
      'UK-IR': { ru: 'Северная Ирландия', en: 'Northern Ireland' },
      'NIR': { ru: 'Северная Ирландия', en: 'Northern Ireland' },
      'UK-SC': { ru: 'Шотландия', en: 'Scotland' },
      'SCT': { ru: 'Шотландия', en: 'Scotland' },
      // <== details for the UK countries
      'GA': { ru: 'Габон', en: 'Gabon' },
      'GN': { ru: 'Гвинея', en: 'Guinea' },
      'GM': { ru: 'Гамбия', en: 'Gambia' },
      'GL': { ru: 'Гренландия', en: 'Greenland' },
      'GH': { ru: 'Гана', en: 'Ghana' },
      'OM': { ru: 'Оман', en: 'Oman' },
      'TN': { ru: 'Тунис', en: 'Tunisia' },
      'JO': { ru: 'Иордания', en: 'Jordan' },
      'HR': { ru: 'Хорватия', en: 'Croatia' },
      'HT': { ru: 'Гаити', en: 'Haiti' },
      'HU': { ru: 'Венгрия', en: 'Hungary' },
      'HN': { ru: 'Гондурас', en: 'Honduras' },
      'PR': { ru: 'Пуэрто-Рико', en: 'Puerto Rico' },
      'PS': { ru: 'Палестина', en: 'Palestine' },
      'PT': { ru: 'Португалия', en: 'Portugal' },
      'PY': { ru: 'Парагвай', en: 'Paraguay' },
      'PA': { ru: 'Панама', en: 'Panama' },
      'PG': { ru: 'Папуа Новая Гвинея', en: 'Papua New Guinea' },
      'PE': { ru: 'Перу', en: 'Peru' },
      'PK': { ru: 'Пакистан', en: 'Pakistan' },
      'PH': { ru: 'Филиппины', en: 'Philippines' },
      'PL': { ru: 'Польша', en: 'Poland' },
      'ZM': { ru: 'Замбия', en: 'Zambia' },
      'EE': { ru: 'Эстония', en: 'Estonia' },
      'EG': { ru: 'Египет', en: 'Egypt' },
      'ZA': { ru: 'Юж.Африка', en: 'South Africa' },
      'EC': { ru: 'Эквадор', en: 'Ecuador' },
      'IT': { ru: 'Италия', en: 'Italy' },
      'VN': { ru: 'Вьетнам', en: 'Vietnam' },
      'SB': { ru: 'Соломоновы О-ва', en: 'Solomon Is.' },
      'ET': { ru: 'Эфиопия', en: 'Ethiopia' },
      'SO': { ru: 'Сомали', en: 'Somalia' },
      'ZW': { ru: 'Зимбабве', en: 'Zimbabwe' },
      'ES': { ru: 'Испания', en: 'Spain' },
      'ER': { ru: 'Эритрея', en: 'Eritrea' },
      'ME': { ru: 'Черногория', en: 'Montenegro' },
      'MD': { ru: 'Молдова', en: 'Moldova' },
      'MG': { ru: 'Мадагаскар', en: 'Madagascar' },
      'MA': { ru: 'Марокко', en: 'Morocco' },
      'EH': { ru: 'Зап.Сахара', en: 'W. Sahara' },
      'UZ': { ru: 'Узбекистан', en: 'Uzbekistan' },
      'MM': { ru: 'Мьянма', en: 'Myanmar' },
      'ML': { ru: 'Мали', en: 'Mali' },
      'MN': { ru: 'Монголия', en: 'Mongolia' },
      'MK': { ru: 'Македония', en: 'Macedonia' },
      'MW': { ru: 'Малави', en: 'Malawi' },
      'MR': { ru: 'Мавритания', en: 'Mauritania' },
      'UG': { ru: 'Уганда', en: 'Uganda' },
      'MY': { ru: 'Малайзия', en: 'Malaysia' },
      'MX': { ru: 'Мексика', en: 'Mexico' },
      'IL': { ru: 'Израиль', en: 'Israel' },
      'FR': { ru: 'Франция', en: 'France' },
      'XS': { ru: 'Сомалилэнд', en: 'Somaliland' },
      'FI': { ru: 'Финляндия', en: 'Finland' },
      'FJ': { ru: 'Фиджи', en: 'Fiji' },
      'FK': { ru: 'Фолклендские О-ва', en: 'Falkland Is.' },
      'NI': { ru: 'Никарагуа', en: 'Nicaragua' },
      'NL': { ru: 'Нидерланды', en: 'Netherlands' },
      'NO': { ru: 'Норвегия', en: 'Norway' },
      'NA': { ru: 'Намибия', en: 'Namibia' },
      'VU': { ru: 'Вануату', en: 'Vanuatu' },
      'NC': { ru: 'Нов.Каледония', en: 'New Caledonia' },
      'NE': { ru: 'Нигер', en: 'Niger' },
      'NG': { ru: 'Нигерия', en: 'Nigeria' },
      'NZ': { ru: 'Нов.Зеландия', en: 'New Zealand' },
      'NP': { ru: 'Непал', en: 'Nepal' },
      'CI': { ru: "Кот-д'Ивуар", en: "Côte d'Ivoire" },
      'CH': { ru: 'Греция', en: 'Switzerland' },
      'CO': { ru: 'Колумбия', en: 'Colombia' },
      'CN': { ru: 'Китай', en: 'China' },
      'CM': { ru: 'Камерун', en: 'Cameroon' },
      'CL': { ru: 'Чили', en: 'Chile' },
      'XC': { ru: 'Сев.Кипр', en: 'N. Cyprus' },
      'CA': { ru: 'Канада', en: 'Canada' },
      'CG': { ru: 'Конго', en: 'Congo' },
      'CF': { ru: 'Центр.Африка', en: 'Central African Rep.' },
      'CD': { ru: 'Дем.Респ.Конго', en: 'Dem. Rep. Congo' },
      'CZ': { ru: 'Чехия', en: 'Czech Rep.' },
      'CY': { ru: 'Кипр', en: 'Cyprus' },
      'CR': { ru: 'Коста Рика', en: 'Costa Rica' },
      'CU': { ru: 'Куба', en: 'Cuba' },
      'SZ': { ru: 'Свазиленд', en: 'Swaziland' },
      'SY': { ru: 'Сирия', en: 'Syria' },
      'KG': { ru: 'Киргизия', en: 'Kyrgyzstan' },
      'KE': { ru: 'Кения', en: 'Kenya' },
      'SS': { ru: 'Юж.Судан', en: 'S. Sudan' },
      'SR': { ru: 'Суринам', en: 'Suriname' },
      'KH': { ru: 'Камбоджа', en: 'Cambodia' },
      'SV': { ru: 'Эль-Сальвадор', en: 'El Salvador' },
      'SK': { ru: 'Словакия', en: 'Slovakia' },
      'KR': { ru: 'Корея', en: 'Korea' },
      'SI': { ru: 'Словения', en: 'Slovenia' },
      'KP': { ru: 'Дем.Респ.Корея', en: 'Dem. Rep. Korea' },
      'KW': { ru: 'Кувейт', en: 'Kuwait' },
      'SN': { ru: 'Сенегал', en: 'Senegal' },
      'SL': { ru: 'Сьерра-Леоне', en: 'Sierra Leone' },
      'KZ': { ru: 'Казахстан', en: 'Kazakhstan' },
      'SA': { ru: 'Саудовская Арабия', en: 'Saudi Arabia' },
      'SE': { ru: 'Швеция', en: 'Sweden' },
      'SD': { ru: 'Судан', en: 'Sudan' },
      'DO': { ru: 'Доминиканская Респ.', en: 'Dominican Rep.' },
      'DJ': { ru: 'Джибути', en: 'Djibouti' },
      'DK': { ru: 'Дания', en: 'Denmark' },
      'DE': { ru: 'Германия', en: 'Germany' },
      'YE': { ru: 'Йемен', en: 'Yemen' },
      'DZ': { ru: 'Алжир', en: 'Algeria' },
      'US': { ru: 'США', en: 'United States' },
      'UY': { ru: 'Уругвай', en: 'Uruguay' },
      'LB': { ru: 'Ливан', en: 'Lebanon' },
      'LA': { ru: 'Лаос', en: 'Lao PDR' },
      'TW': { ru: 'Тайвань', en: 'Taiwan' },
      'TT': { ru: 'Тринидад и Тобаго', en: 'Trinidad and Tobago' },
      'TR': { ru: 'Турция', en: 'Turkey' },
      'LK': { ru: 'Шри-Ланка', en: 'Sri Lanka' },
      'LV': { ru: 'Латвия', en: 'Latvia' },
      'LT': { ru: 'Литва', en: 'Lithuania' },
      'LU': { ru: 'Люксембург', en: 'Luxembourg' },
      'LR': { ru: 'Либерия', en: 'Liberia' },
      'LS': { ru: 'Лесото', en: 'Lesotho' },
      'TH': { ru: 'Таиланд', en: 'Thailand' },
      'TF': { ru: 'Фр.Юж.Антарктика', en: 'Fr. S. Antarctic Lands' },
      'TG': { ru: 'Того', en: 'Togo' },
      'TD': { ru: 'Чад', en: 'Chad' },
      'LY': { ru: 'Ливия', en: 'Libya' },
      'AE': { ru: 'ОАЭ', en: 'United Arab Emirates' },
      'VE': { ru: 'Венесуэлла', en: 'Venezuela' },
      'AF': { ru: 'Афганистан', en: 'Afghanistan' },
      'IQ': { ru: 'Ирак', en: 'Iraq' },
      'IS': { ru: 'Исландия', en: 'Iceland' },
      'IR': { ru: 'Иран', en: 'Iran' },
      'AM': { ru: 'Армения', en: 'Armenia' },
      'AL': { ru: 'Албания', en: 'Albania' },
      'AO': { ru: 'Ангола', en: 'Angola' },
      'AR': { ru: 'Аргентина', en: 'Argentina' },
      'AU': { ru: 'Австралия', en: 'Australia' },
      'AT': { ru: 'Австрия', en: 'Austria' },
      'IN': { ru: 'Индия', en: 'India' },
      'TZ': { ru: 'Танзания', en: 'Tanzania' },
      'AZ': { ru: 'Азербайджан', en: 'Azerbaijan' },
      'IE': { ru: 'Ирландия', en: 'Ireland' },
      'ID': { ru: 'Индонезия', en: 'Indonesia' },
      'UA': { ru: 'Украина', en: 'Ukraine' },
      'QA': { ru: 'Катар', en: 'Qatar' },
      'MZ': { ru: 'Мозамбик', en: 'Mozambique' },
      
      //-- RUSSIAN REGIONS
      'RU-PNZ': { ru: 'Пензенская область', en: 'Penzenskaya oblast' },
      'RU-VLA': { ru: 'Владимирская область', en: 'Vladimirskaya oblast' },
      'RU-LEN': { ru: 'Ленинградская область', en: 'Leningradskaya oblast' },
      'RU-SAK': { ru: 'Сахалинская область', en: 'Sakhalinskaya oblast' },
      'RU-KYA': { ru: 'Красноярский край', en: 'Krasnoyarskiy kray' },
      'RU-UD':  { ru: 'Удмуртская Республика', en: 'Udmurtskaya Respublika' },
      'RU-IVA': { ru: 'Ивановская область', en: 'Ivanovskaya oblast' },
      'RU-LIP': { ru: 'Липецкая область', en: 'Lipetskaya oblast' },
      'RU-AST': { ru: 'Астраханская область', en: 'Astrakhanskaya oblast' },
      'RU-CE':  { ru: 'Чеченская Республика', en: 'Chechenskaya Respublika' },
      'RU-KHA': { ru: 'Хабаровский край', en: 'Khabarovskiy kray' },
      'RU-ORE': { ru: 'Оренбургская область', en: 'Orenburgskaya oblast' },
      'RU-KIR': { ru: 'Кировская область', en: 'Kirovskaya oblast' },
      'RU-BA':  { ru: 'Башкортостан, Республика', en: 'Bashkortostan, Respublika' },
      'RU-NGR': { ru: 'Новгородская область', en: 'Novgorodskaya oblast' },
      'RU-KLU': { ru: 'Калужская область', en: 'Kaluzhskaya oblast' },
      'RU-OMS': { ru: 'Омская область', en: 'Omskaya oblast' },
      'RU-SAR': { ru: 'Саратовская область', en: 'Saratovskaya oblast' },
      'RU-ORL': { ru: 'Орловская область', en: 'Orlovskaya oblast' },
      'RU-STA': { ru: 'Ставропольский край', en: 'Stavropolskiy kray' },
      'RU-SE':  { ru: 'Северная Осетия-Алания, Республика', en: 'Severnaya Osetiya-Alaniya, Respublika' },
      'RU-SAM': { ru: 'Самарская область', en: 'Samarskaya oblast' },
      'RU-SA':  { ru: 'Саха, Республика', en: 'Sakha, Respublika' },
      'RU-SVE': { ru: 'Свердловская область', en: 'Sverdlovskaya oblast' },
      'RU-KK':  { ru: 'Хакасия, Республика', en: 'Khakasiya, Respublika' },
      'RU-SPE': { ru: 'Санкт-Петербург', en: 'Sankt-Peterburg' },
      'RU-MOS': { ru: 'Московская область', en: 'Moskovskaya oblast' },
      'RU-BEL': { ru: 'Белгородская область', en: 'Belgorodskaya oblast' },
      'RU-KHM': { ru: 'Ханты-Мансийский автономный округ', en: 'Khanty-Mansiyskiy avtonomnyy okrug' },
      'RU-VLG': { ru: 'Вологодская область', en: 'Vologodskaya oblast' },
      'RU-CHE': { ru: 'Челябинская область', en: 'Chelyabinskaya oblast' },
      'RU-YAR': { ru: 'Ярославская область', en: 'Yaroslavskaya oblast' },
      'RU-TUL': { ru: 'Тульская область', en: 'Tulskaya oblast' },
      'RU-IRK': { ru: 'Иркутская область', en: 'Irkutskaya oblast' },
      'RU-NIZ': { ru: 'Нижегородская область', en: 'Nizhegorodskaya oblast' },
      'RU-YAN': { ru: 'Ямало-Ненецкий автономный округ', en: 'Yamalo-Nenetskiy avtonomnyy okrug' },
      'RU-KGD': { ru: 'Калининградская область', en: 'Kaliningradskaya oblast' },
      'RU-MOW': { ru: 'Москва', en: 'Moskva' },
      'RU-KAM': { ru: 'Камчатский край', en: 'Kamchatskiy kray' },
      'RU-BU':  { ru: 'Бурятия, Республика', en: 'Buryatiya, Respublika' },
      'RU-KEM': { ru: 'Кемеровская область', en: 'Kemerovskaya oblast' },
      'RU-CHU': { ru: 'Чукотский автономный округ', en: 'Chukotskiy avtonomnyy okrug' },
      'RU-ULY': { ru: 'Ульяновская область', en: 'Ulyanovskaya oblast' },
      'RU-KGN': { ru: 'Курганская область', en: 'Kurganskaya oblast' },
      'RU-KRS': { ru: 'Курская область', en: 'Kurskaya oblast' },
      'RU-KR':  { ru: 'Карелия, Республика', en: 'Kareliya, Respublika' },
      'RU-ME':  { ru: 'Марий Эл, Республика', en: 'Mariy El, Respublika' },
      'RU-IN':  { ru: 'Ингушетия, Республика', en: 'Ingushetiya, Respublika' },
      'RU-MAG': { ru: 'Магаданская область', en: 'Magadanskaya oblast' },
      'RU-MO':  { ru: 'Мордовия, Республика', en: 'Mordoviya, Respublika' },
      'RU-TA':  { ru: 'Татарстан, Республика', en: 'Tatarstan, Respublika' },
      'RU-ZAB': { ru: 'Забайкальский край', en: 'Zabaykalskiy kray' },
      'RU-RYA': { ru: 'Рязанская область', en: 'Ryazanskaya oblast' },
      'RU-TAM': { ru: 'Тамбовская область', en: 'Tambovskaya oblast' },
      'RU-ARK': { ru: 'Архангельская область', en: 'Arkhangelskaya oblast' },
      'RU-KC':  { ru: 'Карачаево-Черкесская Республика', en: 'Karachayevo-Cherkesskaya Respublika' },
      'RU-TY':  { ru: 'Тура, Республика', en: 'Tyva, Respublika' },
      'RU-MUR': { ru: 'Мурманская область', en: 'Murmanskaya oblast' },
      'RU-VOR': { ru: 'Воронежская область', en: 'Voronezhskaya oblast' },
      'RU-PSK': { ru: 'Псковская область', en: 'Pskovskaya oblast' },
      'RU-TVE': { ru: 'Тверская область', en: 'Tverskaya oblast' },
      'RU-VGG': { ru: 'Волгоградская область', en: 'Volgogradskaya oblast' },
      'RU-KOS': { ru: 'Костромская область', en: 'Kostromskaya oblast' },
      'RU-KL':  { ru: 'Калмыкия, Республика', en: 'Kalmykiya, Respublika' },
      'RU-TOM': { ru: 'Томская область', en: 'Tomskaya oblast' },
      'RU-KO':  { ru: 'Коми, Республика', en: 'Komi, Respublika' },
      'RU-TYU': { ru: 'Тюменская область', en: 'Tyumenskaya oblast' },
      'RU-DA':  { ru: 'Дагестан, Республика', en: 'Dagestan, Respublika' },
      'RU-NVS': { ru: 'Новосибирская область', en: 'Novosibirskaya oblast' },
      'RU-AD':  { ru: 'Адыгея, Республика', en: 'Adygeya, Respublika' },
      'RU-PER': { ru: 'Пермский край', en: 'Permskiy kray' },
      'RU-ROS': { ru: 'Ростовская область', en: 'Rostovskaya oblast' },
      'RU-PRI': { ru: 'Приморский край', en: 'Primorskiy kray' },
      'RU-KB':  { ru: 'Кабардино-Балкарская Республика', en: 'Kabardino-Balkarskaya Respublika' },
      'RU-AL':  { ru: 'Алтай, Республика', en: 'Altay, Respublika' },
      'RU-NEN': { ru: 'Ненецкий автономный округ', en: 'Nenetskiy avtonomnyy okrug' },
      'RU-ALT': { ru: 'Алтайский край', en: 'Altayskiy kray' },
      'RU-KDA': { ru: 'Краснодарский край', en: 'Krasnodarskiy kray' },
      'RU-YEV': { ru: 'Еврейская автономная область', en: 'Yevreyskaya avtonomnaya oblast' },
      'RU-AMU': { ru: 'Амурская область', en: 'Amurskaya oblast' },
      'RU-BRY': { ru: 'Брянская область', en: 'Bryanskaya oblast' },
      'RU-SMO': { ru: 'Смоленская область', en: 'Smolenskaya oblast' },
      'RU-CU':  { ru: 'Чувашская Республика', en: 'Chuvashskaya Respublika' },
      'RU-CR':  { ru: 'Крым, Республика', en: 'Crimea Republic' },
    }
  }
}
